import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const RoadsideAssistance = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Dependable Roadside Assistance in New Orleans, LA</Heading>
                <Text>
                    When you're on the road and face unexpected issues, having immediate assistance is crucial. New
                    Orleans Towing Service provides comprehensive roadside assistance in New Orleans, LA. Whether it's
                    flat tires, dead batteries, lockouts, or fuel delivery, our team is prepared to handle a variety of
                    roadside emergencies.
                </Text>
                <Text>
                    We're committed to delivering quick, reliable, and courteous service to get you back on your journey
                    as soon as possible.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>24/7 Availability:</b> Our roadside assistance services are always available, ensuring you're
                        never stranded, regardless of the time or day.
                    </ListItem>
                    <ListItem>
                        <b>Wide Range of Services:</b> Our capabilities include tire changes, battery jump-starts,
                        emergency fuel delivery, and car lockout solutions.
                    </ListItem>
                    <ListItem>
                        <b>Experienced Technicians:</b> Our team consists of trained professionals equipped to
                        efficiently and safely handle various roadside emergencies.
                    </ListItem>
                    <ListItem>
                        <b>Fast Response Times:</b> Understanding the urgency of roadside issues, we strive to reach you
                        quickly, reducing your inconvenience and wait time.
                    </ListItem>
                    <ListItem>
                        <b>Customer-Centric Approach:</b> Your safety and satisfaction are our top priorities. We
                        provide personalized service tailored to your specific roadside needs.
                    </ListItem>
                </UnorderedList>
                <Text>
                    For reliable roadside assistance in New Orleans, LA, turn to New Orleans Towing Service. We're here
                    to offer the support and care you need in challenging times.
                </Text>
                <Heading as={'h4'}>Immediate Roadside Support Available</Heading>
                <Text>
                    If you need roadside assistance in New Orleans, LA, don't hesitate to contact us. Our team is ready
                    to provide rapid and effective solutions to your roadside challenges.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for prompt roadside assistance.
                    </Link>
                </Text>
                <Text>
                    Rely on New Orleans Towing Service for all your roadside assistance requirements and experience the
                    peace of mind that comes with our expert services.
                </Text>
            </Stack>
        </Box>
    )
}