import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const EmergencyTowing = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Responsive Emergency Towing Services in New Orleans, LA</Heading>
                <Text>
                    In the event of unexpected vehicle emergencies, timely response and professional assistance are
                    essential.
                    New Orleans Towing Service provides top-notch emergency towing in New Orleans, LA, ensuring rapid
                    and
                    effective aid when you need it most. Whether facing a breakdown, accident, or any roadside
                    emergency,
                    our team is ready to respond swiftly to your call.
                </Text>
                <Text>
                    Our emergency towing services are designed to bring you peace of mind in stressful times. We
                    understand
                    the urgency and offer fast towing solutions to ensure both your safety and your vehicle's security.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>24/7 Availability:</b> Our emergency services are always available, providing round-the-clock
                        support every day, so we're there whenever you need us.
                    </ListItem>
                    <ListItem>
                        <b>Fast Response:</b> We prioritize immediate response to emergency calls, ensuring our team
                        reaches
                        you as soon as possible.
                    </ListItem>
                    <ListItem>
                        <b>Skilled and Well-Equipped Team:</b> Our tow truck operators are expertly trained and equipped
                        to handle various emergency situations efficiently and safely.
                    </ListItem>
                    <ListItem>
                        <b>Secure Towing:</b> Utilizing advanced tow trucks and methods, we ensure safe towing of your
                        vehicle, minimizing any potential for additional damage.
                    </ListItem>
                    <ListItem>
                        <b>Comprehensive Services:</b> Beyond towing, we offer a full range of roadside assistance
                        services,
                        including <Link href={"/roadside-assistance"}>roadside help</Link>, <Link
                        href={"/vehicle-recovery"}>
                        vehicle recovery</Link>, <Link href={"/flatbed-towing"}>flatbed towing</Link>, and <Link
                        href={"/car-lockout-service"}>
                        car lockout services</Link>, addressing all your roadside emergency requirements.
                    </ListItem>
                </UnorderedList>
                <Text>
                    For emergency towing situations in New Orleans, rely on New Orleans Towing Service as your trusted
                    partner.
                    We are dedicated to delivering prompt, secure, and professional towing services throughout New
                    Orleans, LA.
                </Text>
                <Heading as={'h4'}>Emergency Towing for Various Scenarios</Heading>
                <Text>
                    Our emergency towing services are ready for a broad spectrum of scenarios, from minor roadside
                    issues to
                    significant accidents. Our team is always prepared to provide the necessary support and towing
                    solutions,
                    no matter the challenge.
                </Text>
                <Heading as={'h4'}>Immediate Assistance Available</Heading>
                <Text>
                    In a vehicle emergency in New Orleans, LA, don't hesitate to contact New Orleans Towing Service.
                    We're here
                    to offer quick, dependable, and compassionate service in your time of need.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for immediate emergency towing assistance.
                    </Link>
                </Text>
                <Text>
                    Trust us for your emergency towing needs and experience the assurance that comes with our
                    professional and
                    caring service.
                </Text>
            </Stack>
        </Box>
    )
}