import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const LongDistanceTowing = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Reliable Long-Distance Towing in New Orleans, LA</Heading>
                <Text>
                    When you need to transport your vehicle over a long distance, trust New Orleans Towing Service for
                    secure and efficient long-distance towing in New Orleans, LA. Whether you're relocating, purchasing
                    a vehicle from afar, or need transportation for a non-operational vehicle, our team is equipped to
                    handle your needs with utmost care and professionalism.
                </Text>
                <Text>
                    Our long-distance towing services are designed to provide a hassle-free experience, ensuring your
                    vehicle reaches its destination safely and on schedule.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Safe and Secure Transport:</b> We use advanced towing equipment and methods to ensure your
                        vehicle is transported safely, regardless of the distance.
                    </ListItem>
                    <ListItem>
                        <b>Experienced Team:</b> Our operators are skilled in handling a variety of vehicles and towing
                        scenarios, ensuring expert service throughout the journey.
                    </ListItem>
                    <ListItem>
                        <b>Customized Solutions:</b> We cater to your specific towing needs, offering personalized
                        service to meet the unique requirements of your situation.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Availability:</b> Our long-distance towing services are available any time, providing
                        flexibility and reliability for your transportation needs.
                    </ListItem>
                    <ListItem>
                        <b>Comprehensive Service:</b> Alongside towing, we offer additional services such as <Link
                        href={"/roadside-assistance"}>roadside assistance</Link> and <Link href={"/vehicle-recovery"}>vehicle
                        recovery</Link>, covering all your vehicle transportation needs.
                    </ListItem>
                </UnorderedList>
                <Text>
                    For dependable long-distance towing services in New Orleans, LA, choose New Orleans Towing Service.
                    We are committed to delivering your vehicle safely and efficiently, wherever the destination.
                </Text>
                <Heading as={'h4'}>Contact Us for Long-Distance Towing Needs</Heading>
                <Text>
                    If you need long-distance towing services in New Orleans, LA, don't hesitate to reach out to us. Our
                    team is ready to provide professional and reliable towing services tailored to your requirements.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for your long-distance towing needs.
                    </Link>
                </Text>
                <Text>
                    Depend on New Orleans Towing Service for expert long-distance towing solutions and enjoy the peace
                    of mind that comes with our specialized service.
                </Text>
            </Stack>
        </Box>
    )
}