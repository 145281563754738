import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const FuelDelivery = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Efficient Fuel Delivery in New Orleans, LA</Heading>
                <Text>
                    Running out of fuel can be an inconvenient and stressful situation. New Orleans Towing Service
                    offers rapid and dependable fuel delivery services in New Orleans, LA, ensuring you're never
                    stranded due to an empty gas tank. Our team is equipped to deliver fuel to your location quickly,
                    helping you resume your journey with minimal delay.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Quick Fuel Delivery:</b> We understand the urgency of your situation and strive to deliver
                        fuel to you as promptly as possible.
                    </ListItem>
                    <ListItem>
                        <b>All Vehicle Types:</b> Whether you drive a car, truck, motorcycle, or any other type of
                        vehicle, we can provide the fuel you need.
                    </ListItem>
                    <ListItem>
                        <b>Safe and Professional Service:</b> Our team follows strict safety protocols to ensure a
                        secure fuel delivery process.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Availability:</b> Our fuel delivery service is available at all times, providing you
                        with the assurance of support whenever you need it.
                    </ListItem>
                    <ListItem>
                        <b>Comprehensive Roadside Assistance:</b> Besides fuel delivery, we offer a range of services
                        including <Link href={"/flat-tire-services"}>flat tire services</Link> and <Link
                        href={"/jump-start-service"}>jump start service</Link>, covering all your roadside assistance
                        needs.
                    </ListItem>
                </UnorderedList>
                <Text>
                    For efficient and reliable fuel delivery in New Orleans, LA, turn to New Orleans Towing Service.
                    We're here to ensure you're back on your journey without unnecessary disruptions.
                </Text>
                <Heading as={'h4'}>Immediate Fuel Delivery Assistance</Heading>
                <Text>
                    If you're out of gas in New Orleans, LA, contact New Orleans Towing Service for quick and efficient
                    fuel delivery. Our team is ready to assist you with timely and professional service.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for fast fuel delivery.
                    </Link>
                </Text>
                <Text>
                    Rely on New Orleans Towing Service for all your fuel delivery needs and experience the convenience
                    and efficiency of our service.
                </Text>
            </Stack>
        </Box>

    )
}