import React from "react";
import {posts} from "./posts";
import {Box, Heading, Link, List, ListItem, Stack, Text} from "@chakra-ui/react";
import {CarLockoutService} from "../Components/CarLockoutService/CarLockoutService";
import {VehicleRecovery} from "../Components/VehicleRecovery/VehicleRecovery";
import {LongDistanceTowing} from "../Components/LongDistanceTowing/LongDistanceTowing";
import {FlatbedTowing} from "../Components/FlatbedTowing/FlatbedTowing";
import {RoadsideAssistance} from "../Components/RoadsideAssistance/RoadsideAssistance";
import {EmergencyTowing} from "../Components/EmergencyTowing/EmergencyTowing";
import {FlatTire} from "../Components/FlatTire/FlatTire";
import {JumpStart} from "../Components/JumpStartService/JumpStart";
import {FuelDelivery} from "../Components/FuelDelivery/FuelDelivery";
import {GiCarBattery, GiFlatTire, GiFuelTank, GiPathDistance, GiTowTruck} from "react-icons/gi";
import {MdEmergencyShare} from "react-icons/md";
import {RiEmotionHappyLine} from "react-icons/ri";
import {PiClockCountdownFill} from "react-icons/pi";
import {BiLockOpenAlt} from "react-icons/bi";
import {BsTruckFlatbed} from "react-icons/bs";
import {TbRoad} from "react-icons/tb";
import newOrleansTowingMeta from '../assets/images/meridenmeta.jpg'

export const SiteData = {
    "hoursOfOperation": "24/7",
    "city": "New Orleans, LA",
    "phoneNumber": "(504) 294-5898",
    "telLink": "tel:5042945898",
    "title": "Towing Service in New Orleans, LA | 24-Hour Towing | 504-294-5898",
    "buttonCtaText": "Call Now for 24/7 Emergency Towing Assistance in New Orleans!",
    "keywords": "Towing, services, affordable, emergency, reliable, New Orleans, LA, roadside assistance, vehicle recovery.",
    "footerText": "New Orleans Towing Service, All rights reserved.",
    "ogImage": newOrleansTowingMeta,
    "navLinks": [
        {
            "title": "Home",
            "href": "/"
        },
        {
            "title": "Services",
            "href": "/services"
        },
        {
            "title": "Blog",
            "href": "/blog"
        },
        {
            "title": "About",
            "href": "/about"
        },
        {
            "title": "Contact Us",
            "href": "/contact"
        },
        {
            "title": "Careers",
            "href": "/careers",
            "hideFromTopNav": true
        }
    ],
    signupForm: {
        // intro: () => <Text>We are proud to be Meriden's top choice for towing services. We provide a variety of towing
        //     services for all size vehicles big and small. Count on Meriden Towing Service to provide reliable,
        //     trustworthy vehicle towing to our local community and the surrounding areas. We offer on-the-spot assistance
        //     24/7 to get your car, truck, or other vehicle to safety. We're just a <Link href={'tel:4752428150'}
        //                                                                                 textDecoration={'underline'}>phone
        //         call away.</Link></Text>,
        // servicesTitle: 'Our Services:',
    },
    "homepage": {
        "url": "https://www.neworleanstowtruck.com",
        "bgImage": "newOrleansTowingBackground",
        "metaData": {
            "title": "New Orleans Towing Services: Fast, Reliable, 24/7 Assistance | Call 504-294-5898",
            "description": "Experience top-notch towing services in New Orleans. Available 24/7 for all your towing needs. Contact us at 504-294-5898 for prompt, professional assistance."
        },
        "h1": "24/7 Towing in New Orleans, Louisiana",
        "subtitle": "Your Go-To Solution for Professional Towing and Roadside Assistance in New Orleans",
        "servicesTitle": "Discover Why New Orleans Chooses Us for Towing Services",
        "servicesHeader": "Comprehensive Towing and Roadside Solutions for The Crescent City",
        "titleHead": "TOWING EXCELLENCE IN THE BIG EASY",
        "sectionTitle": "Dedication and Reliability: Our Promise to New Orleans",
        "content": [
            <Box>
                <Stack spacing={5}>
                    <Heading as="h2" size="xl">New Orleans Towing Service: Your Reliable Roadside Ally</Heading>
                    <Text>New Orleans Towing Service stands as your premier choice for professional towing and roadside
                        assistance in New Orleans, LA. We specialize in providing rapid, dependable, and secure
                        services, catering to various needs like <Link href='/emergency-towing' color='teal.500'>emergency
                            towing</Link>, roadside crises, and more. Our unwavering commitment to excellence and
                        customer satisfaction distinguishes us as a leading service provider in the city.</Text>

                    <Heading as="h3" size="lg">Why Choose New Orleans Towing Service?</Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text><b>Expert Team:</b> Our experienced professionals are thoroughly trained, handling a
                                broad spectrum of towing situations with precision and care, ensuring your vehicle’s
                                safety.</Text>
                        </ListItem>
                        <ListItem>
                            <Text><b>State-of-the-Art Equipment:</b> Utilizing advanced towing technology and tools, we
                                are equipped to offer exemplary service, whether it's a standard tow or a complex
                                recovery task.</Text>
                        </ListItem>
                        <ListItem>
                            <Text><b>24/7 Availability:</b> Understanding the unpredictability of roadside emergencies,
                                our services are accessible around the clock, guaranteeing assistance at any
                                moment.</Text>
                        </ListItem>
                        <ListItem>
                            <Text><b>Affordable Rates:</b> We believe in transparent, competitive pricing, providing
                                top-notch services at economical rates.</Text>
                        </ListItem>
                        <ListItem>
                            <Text><b>Local Insights:</b> Our extensive knowledge of New Orleans and surrounding areas
                                enables us to offer efficient service and swift response times.</Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">Comprehensive Towing & Roadside Assistance Services</Heading>
                    <List spacing={3}>
                        <ListItem>
                            <Text><b>Emergency Towing:</b> Equipped to manage all vehicle types in emergencies, we
                                prioritize your safety and your vehicle's security during transit. Explore our <Link
                                    href='/emergency-towing' color='teal.500'>Emergency Towing</Link> services.</Text>
                        </ListItem>
                        <ListItem>
                            <Text><b>Roadside Assistance:</b> Facing a flat tire, dead battery, or out of fuel? Our team
                                is ready to offer quick roadside aid to get you moving with minimal delay. Learn more
                                about our <Link href='/roadside-assistance' color='teal.500'>Roadside
                                    Assistance</Link> services.</Text>
                        </ListItem>
                        <ListItem>
                            <Text><b>Flat Tire Services:</b> Quick tire changes and repairs are our forte, ensuring you
                                resume your journey safely and efficiently. Discover our <Link
                                    href='/flat-tire-services' color='teal.500'>Flat Tire Services</Link>.</Text>
                        </ListItem>
                        <ListItem>
                            <Text><b>Vehicle Lockout Services:</b> Our non-invasive lockout solutions regain your
                                vehicle access swiftly without any damage. Check out our <Link
                                    href='/car-lockout-service' color='teal.500'>Vehicle Lockout Services</Link>.</Text>
                        </ListItem>
                        <ListItem>
                            <Text><b>Long-Distance Towing:</b> We provide dependable long-distance towing, ensuring your
                                vehicle's safe transport to any destination. Find out more about our <Link
                                    href='/long-distance-towing' color='teal.500'>Long-Distance Towing</Link> solutions.</Text>
                        </ListItem>
                    </List>

                    <Heading as="h3" size="lg">Tailored Solutions for Individual & Business Needs</Heading>
                    <Text>We understand that each client has unique needs. Our tailored towing and roadside assistance
                        solutions cater to both personal and business requirements, delivering services with utmost
                        professionalism.</Text>

                    <Heading as="h3" size="lg">Prioritizing Your Safety and Satisfaction</Heading>
                    <Text>At New Orleans Towing Service, your safety and satisfaction are our highest priority. We are
                        committed to providing superior services that exceed your expectations, ensuring a smooth and
                        hassle-free experience.</Text>

                    <Heading as="h3" size="lg">Contact Us Today</Heading>
                    <Text>For exceptional towing and roadside assistance in New Orleans, LA, turn to New Orleans Towing
                        Service. Reach out to us for expert and prompt service. We're here to support you whenever you
                        need us. Call 504-294-5898 for immediate assistance.</Text>
                </Stack>
            </Box>
        ],
        "values": [
            {
                "id": 0,
                "number": "Thousands",
                "name": "Vehicles Towed",
                "icon": GiTowTruck
            },
            {
                "id": 1,
                "number": "24/7",
                "name": "Service Availability",
                "icon": MdEmergencyShare
            },
            {
                "id": 2,
                "number": "98%",
                "name": "Customer Satisfaction",
                "icon": RiEmotionHappyLine
            },
            {
                "id": 3,
                "number": "30min",
                "name": "Average Response Time",
                "icon": PiClockCountdownFill
            }
        ],
        "servicesSubtitle": "Explore our comprehensive suite of services – from quick tows to detailed roadside assistance, New Orleans Towing Service is your all-inclusive solution for any vehicle emergency",
        "featuredServices": [
            {
                "name": 'Emergency Towing Service',
                "description": 'Round-the-clock emergency towing services, ensuring prompt help in the vibrant streets of New Orleans. Whether it’s a breakdown or an accident, we’re here to help at any hour.',
                "icon": MdEmergencyShare,
                "path": '/emergency-towing'
            },
            {
                "name": 'Roadside Assistance',
                "description": 'Comprehensive roadside assistance services, from tire changes to jump starts, ensuring you’re never left stranded in the Big Easy.',
                "icon": TbRoad,
                "path": '/roadside-assistance'
            },
            {
                "name": 'Flatbed Towing',
                "description": 'Specialized flatbed towing services for the safe and secure transport of your vehicle, perfect for luxury cars, vintage automobiles, and motorcycles.',
                "icon": BsTruckFlatbed,
                "path": '/flatbed-towing'
            },
            {
                "name": 'Vehicle Lockout Service',
                "description": 'Quick and professional car lockout services to help you regain access to your vehicle without any damage, available throughout New Orleans.',
                "icon": BiLockOpenAlt,
                "path": '/car-lockout-service'
            },
            {
                "name": 'Long-Distance Towing',
                "description": 'Reliable long-distance towing services for transporting your vehicle across states or to distant destinations, ensuring a safe journey for your vehicle.',
                "icon": GiPathDistance,
                "path": '/long-distance-towing'
            },
            {
                name: 'Car Lockout Assistances',
                description:
                    'Got locked out of your car? Don\'t panic! Our New Orleans-based team offers reliable and damage-free car lockout services. Whether you\'re stranded at home, work, or on the road, our professional locksmiths are just a call away, ready to provide quick and efficient access to your vehicle. Reach out now for immediate assistance.',
                icon: BiLockOpenAlt,
                path: '/car-lockout-service'
            },
            {
                name: 'Flat Tire Solutions',
                description:
                    'Dealing with a flat tire in New Orleans? Our skilled team at New Orleans Tow Truck Service is here to offer fast and competent flat tire support. Whether you need a tire change or repair, we\'re equipped to handle it all, ensuring you\'re back on the road in no time. Experience prompt, reliable service that gets you moving without delay.',
                icon: GiFlatTire,
                path: '/flat-tire-services'
            },
            {
                name: 'Fuel Delivery',
                description: 'Running out of fuel can be a major inconvenience, especially when you\'re far from a gas station. New Orleans Towing Service offers fuel delivery services, providing you with enough fuel to reach your destination or the nearest gas station. It\'s a hassle-free solution to keep you moving.',
                icon: GiFuelTank,
                path: '/fuel-delivery',
            },
            {
                name: 'Jump-Start',
                description: 'A dead battery can bring your day to a standstill. In such situations, a quick jump-start can save the day. Our team is skilled in jump-starting vehicles, ensuring you\'re not left stranded due to a drained battery. Whether you\'re in the French Quarter or the Garden District, we\'re just a call away.',
                icon: GiCarBattery,
                path: '/jump-start-service',
            }
        ],
        "services": [
            {
                "id": '0',
                "title": 'Immediate Response',
                "text": "In any vehicle emergency in New Orleans, rely on us for swift assistance. We’re equipped to respond quickly to your call, 24/7."
            },
            {
                "id": '1',
                "title": 'Experienced Team',
                "text": "Our team comprises certified, licensed, and insured professionals, trained to handle a variety of roadside situations with expertise and care."
            },
            {
                "id": '2',
                "title": 'Competitive Pricing',
                "text": "We offer transparent, fair pricing for all our services, ensuring high-quality towing and roadside assistance at a reasonable cost."
            },
            {
                "id": '3',
                "title": 'Comprehensive Services',
                "text": "From emergency towing to specialized roadside assistance, we have a wide range of services to cover all your vehicle needs in New Orleans."
            },
            {
                "id": '4',
                "title": 'Local Knowledge',
                "text": "Our deep understanding of New Orleans’ streets and neighborhoods enables us to provide efficient service and quick navigation."
            }
        ],
        "footerHeading": "Contact New Orleans Towing Services",
        "footerText": "For top-notch roadside assistance in New Orleans and surrounding areas, reach out to us 24/7. We're available to help with any towing or vehicle emergency. Call us at 504-294-5898 or fill out our online contact form for non-emergency inquiries. Your safety and satisfaction are our top priority at New Orleans Towing Services.",
        "footerText2": "Ensuring Your Peace of Mind on the Road"
    },
    "careers": {
        "url": "https://www.neworleanstowtruck.com/careers/",
        "metaData": {
            "title": "Career Opportunities at New Orleans Towing Service | Join Our Team",
            "description": "Explore exciting career paths at New Orleans Towing Service. We offer a dynamic work environment for towing professionals in New Orleans, LA."
        },
        "h1": "We're Hiring - Join Our Team at New Orleans Towing Service",
        "h2": [
            "Why New Orleans Towing Service is a Great Place to Work",
            "Find Your Role - Current Job Openings",
            "Investing in Our Team's Growth and Satisfaction"
        ],
        "headingText": {
            "whyJoin": "Join a team where your skills and passion lead to meaningful work. New Orleans Towing Service is dedicated to providing outstanding towing and roadside assistance in New Orleans.",
            "openings": "Check out our latest job openings and find a role that suits your expertise and ambitions in the towing industry.",
            "commitment": "We're committed to fostering a supportive work environment, prioritizing the development and satisfaction of our team members."
        },
        "content": [
            <Box>
                <Text>Welcome to the Careers section at <Link href={'/'} color='blue.500'>New Orleans Towing
                    Service</Link>. Here, your career in towing and roadside assistance can reach new heights. As more
                    than just a team, we're a family dedicated to excellence, seeking to make a significant impact in
                    New Orleans.</Text>
                <Text>Are you a passionate professional in the towing industry? At <Link href={'/'} color='blue.500'>New
                    Orleans Towing Service</Link>, we're looking for dedicated individuals like you. Whether you're an
                    experienced operator or new to the field, we offer a dynamic and supportive work environment where
                    your skills are valued and developed.</Text>
                <Text>Our team enjoys a culture of support and growth. We provide comprehensive training, competitive
                    compensation, and opportunities to work with advanced equipment. We aim to create a rewarding and
                    enjoyable workplace for everyone on our team.</Text>
                <Text>Being an integral part of the New Orleans community, we pride ourselves on serving with integrity
                    and professionalism. We extend this commitment to our team members, providing a positive work
                    environment, respectful teamwork, and opportunities for advancement.</Text>
                <Text>Ready to join a leading towing service provider in New Orleans? Explore our current job openings
                    and find your place in our growing team. From driving and roadside assistance to customer service
                    roles, there's a spot for you at <Link href={'/'} color='blue.500'>New Orleans Towing Service</Link>.</Text>
                <Text>Discover your next career move with us. Be part of a team reshaping the towing industry in New
                    Orleans. Apply today and drive your career forward with <Link href={'/'} color='blue.500'>New
                        Orleans Towing Service</Link>.</Text>
            </Box>
        ]
    },
    "about": {
        "url": "https://www.neworleanstowtruck.com/about/",
        "metaData": {
            "title": "About New Orleans Towing Service - Expert Towing and Roadside Assistance",
            "description": "Get to know New Orleans Towing Service, your reliable partner for all towing and roadside assistance needs in New Orleans, LA. Available 24/7."
        },
        "h1": "About New Orleans Towing Service",
        "h2": [
            "Our Commitment to Quality Towing and Roadside Assistance",
            "Expert Towing Team at Your Service",
            "Our Range of Towing and Recovery Services"
        ],
        "headingText": {
            "commitment": "Committed to providing top-tier towing and roadside support in New Orleans.",
            "team": "Our team of towing professionals are ready to respond to any call, ensuring quality service every time.",
            "services": "Explore our comprehensive towing solutions, designed to meet all your vehicle recovery needs."
        },
        "services": [
            {
                "id": "0",
                "title": "24-Hour Emergency Towing",
                "href": "/emergency-towing",
                "icon": "MdEmergencyShare",
                "description": "<Text>Our team provides rapid response to emergencies, ensuring prompt and secure towing services throughout New Orleans, day and night.</Text>"
            },
            {
                "id": "1",
                "title": "Roadside Assistance",
                "href": "/roadside-assistance",
                "icon": "TbRoad",
                "description": "<Text>Offering a helping hand with dependable roadside assistance for jump-starts, tire changes, and lockout services.</Text>"
            },
            {
                "id": "2",
                "title": "Vehicle Recovery",
                "href": "/vehicle-recovery",
                "icon": "GiTowTruck",
                "description": "<Text>Efficient and careful vehicle recovery services, aimed at retrieving your vehicle from challenging situations without further complications.</Text>"
            },
            {
                "id": "3",
                "title": "Long-Distance Towing",
                "href": "/long-distance-towing",
                "icon": "GiPathDistance",
                "description": "<Text>Specializing in long-distance towing, offering safe and secure transport for your vehicle to any destination with meticulous attention to your schedule.</Text>"
            },
            {
                "id": "4",
                "title": "Flatbed Towing",
                "href": "/flatbed-towing",
                "icon": "BsTruckFlatbed",
                "description": "<Text>Trust our flatbed towing service for your high-value vehicles, providing the utmost care to prevent any damage during transportation.</Text>"
            },
            {
                "id": "5",
                "title": "Car Lockout Service",
                "href": "/car-lockout-service",
                "icon": "BiLockOpenAlt",
                "description": "<Text>Our car lockout solutions are swift and non-invasive, restoring access to your vehicle promptly without any damage.</Text>"
            },
            {
                "id": "6",
                "title": "Flat Tire Service",
                "href": "/flat-tire-services",
                "icon": "GiFlatTire",
                "description": "<Text>Stranded due to a flat tire? Our team provides quick and efficient tire changing and repair services to get you back on the road safely and promptly.</Text>"
            },
            {
                "id": "7",
                "title": "Fuel Delivery Service",
                "href": "/fuel-delivery",
                "icon": "GiFuelTank",
                "description": "<Text>Ran out of gas? Our fuel delivery service is quick and reliable, bringing fuel directly to your stranded location. We ensure you're back on your journey with minimal delay.</Text>"
            },
            {
                "id": "8",
                "title": "Jump Start Service",
                "href": "/jump-start-service",
                "icon": "GiCarBattery",
                "description": "<Text>Dealing with a dead battery? Our jump start service is here to help. Our skilled technicians provide fast and effective solutions to get your vehicle running again, ensuring a smooth and safe continuation of your travel.</Text>"
            }
        ]
    },
    "services": {
        "url": "https://www.neworleanstowtruck.com/services/",
        "metaData": {
            "title": "Comprehensive Towing Services in New Orleans | New Orleans Towing Service",
            "description": "Explore our wide range of towing services in New Orleans. From emergency assistance to vehicle transport, our expert team is available 24/7. Contact us for efficient towing solutions.",
        },
        "h1": "Towing Services in New Orleans, LA",
        "h2": "Your Reliable Partner for All Towing Needs",
        "content": <Box>
            <Stack spacing={5}>
                <Heading as="h2" size="xl">New Orleans Towing Service: Your Dependable Roadside Companion in New
                    Orleans, LA</Heading>
                <Text>New Orleans Towing Service is proud to be your primary source for professional towing and roadside
                    assistance in New Orleans, LA. We are dedicated to providing prompt, reliable, and safe services,
                    whether you're facing a vehicle breakdown, need emergency towing, or require urgent roadside
                    assistance. Our commitment to excellence and customer satisfaction makes us a leading service
                    provider in the region.</Text>

                <Heading as="h3" size="lg">Why New Orleans Towing Service is the Right Choice</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Skilled Team:</b> Our seasoned professionals are well-trained and experienced in
                        a wide range of towing scenarios, ensuring your vehicle is handled with utmost care and
                        expertise.</Text></ListItem>
                    <ListItem><Text><b>Modern Towing Equipment:</b> We use state-of-the-art towing technology and tools
                        to provide top-quality service, whether it's a straightforward tow or a complex recovery
                        operation.</Text></ListItem>
                    <ListItem><Text><b>24/7 Availability:</b> Roadside emergencies can happen at any time, which is why
                        we offer our services around the clock, ensuring support whenever you need it.</Text></ListItem>
                    <ListItem><Text><b>Affordable Pricing:</b> We offer transparent and fair pricing for all our
                        services, ensuring you receive high-quality service without breaking the bank.</Text></ListItem>
                    <ListItem><Text><b>Local Expertise:</b> With extensive knowledge of New Orleans and its
                        surroundings, we ensure faster response times and efficient service for a smooth towing
                        experience.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Our Broad Range of Towing & Roadside Assistance Services</Heading>
                <List spacing={3}>
                    <ListItem><Text><b>Emergency Towing:</b> Equipped to handle all vehicle types in emergency
                        situations, we prioritize your safety and the security of your vehicle during
                        transportation.</Text></ListItem>
                    <ListItem><Text><b>Roadside Assistance:</b> If you're dealing with a flat tire, a dead battery, or
                        an empty gas tank, our roadside assistance team is ready to help you get back on the road
                        quickly.</Text></ListItem>
                    <ListItem><Text><b>Flat Tire Services:</b> Experiencing a flat tire? Our team can swiftly change or
                        repair your tire, ensuring you're back on the road safely and efficiently.</Text></ListItem>
                    <ListItem><Text><b>Vehicle Lockout Services:</b> Locked out of your car? Our efficient lockout
                        services will regain access to your vehicle without any damage.</Text></ListItem>
                    <ListItem><Text><b>Long-Distance Towing:</b> We provide reliable long-distance towing services,
                        ensuring your vehicle is transported safely to its destination, wherever that may
                        be.</Text></ListItem>
                </List>

                <Heading as="h3" size="lg">Tailored Solutions for Personal & Business Needs</Heading>
                <Text>At New Orleans Towing Service, we understand that every client has unique needs. We offer
                    customized towing and roadside assistance solutions for both individuals and businesses, ensuring we
                    meet your specific requirements with professionalism and attention to detail.</Text>

                <Heading as="h3" size="lg">Prioritizing Safety and Satisfaction</Heading>
                <Text>Your safety and satisfaction are our highest priorities. We are committed to delivering superior
                    quality services that not only meet but exceed your expectations, ensuring a hassle-free and
                    efficient experience from start to finish.</Text>

                <Heading as="h3" size="lg">Contact Us for Assistance</Heading>
                <Text>For top-tier towing and roadside assistance in New Orleans, LA, turn to New Orleans Towing
                    Service. Contact us today for expert, prompt service. We're here to support you whenever you need
                    us.</Text>
            </Stack>
        </Box>,
        "services": [
            {
                "id": "0",
                "text": <Text><b><Link href={'/emergency-towing'} color='blue.500'>24 Hour Emergency
                    Towing:</Link></b> Our fleet is equipped and ready to provide fast, reliable towing in emergencies.
                    Whether you're on the busy streets of downtown New Orleans or on the outskirts, we're quick to
                    locate and assist you.</Text>
            },
            {
                "id": "1",
                "text": <Text><b><Link href={'/roadside-assistance'} color='blue.500'>Roadside
                    Assistance:</Link></b> Need help on the road? Our skilled technicians offer assistance for jump
                    starts, tire changes, and lockouts. Quick and efficient service is just a phone call away.</Text>
            },
            {
                "id": "2",
                "text": <Text><b><Link href={'/vehicle-recovery'} color='blue.500'>Vehicle Recovery:</Link></b> We
                    provide vehicle recovery services for accidents and off-road incidents. Our team ensures safe and
                    efficient recovery of your vehicle, regardless of the situation.</Text>
            },
            {
                "id": "3",
                "text": <Text><b><Link href={'/long-distance-towing'} color='blue.500'>Long-Distance
                    Towing:</Link></b> Need your vehicle towed over a long distance? We offer secure and reliable
                    long-distance towing services to get your vehicle where it needs to go, safely and on
                    schedule.</Text>
            },
            {
                "id": "4",
                "text": <Text><b><Link href={'/flatbed-towing'} color='blue.500'>Flatbed Towing:</Link></b> Our flatbed
                    towing services are perfect for transporting high-value or specialty vehicles. We provide safe,
                    secure towing that protects your vehicle throughout its journey.</Text>
            },
            {
                "id": "5",
                "text": <Text><b><Link href={'/car-lockout-service'} color='blue.500'>Car Lockout
                    Service:</Link></b> Locked out of your car? Our team offers quick and non-invasive lockout services
                    to help you regain access to your vehicle without any damage.</Text>
            },
            {
                "id": "6",
                "text": <Text><b><Link href={'/fuel-delivery'} color='blue.500'>Fuel Delivery Service:</Link></b> Run
                    out of gas? We provide fast and reliable fuel delivery services to get you back on the road without
                    any long delays.</Text>
            },
            {
                "id": "7",
                "text": <Text><b><Link href={'/jump-start-service'} color='blue.500'>Jump Start
                    Service:</Link></b> Dealing with a dead battery? Our jump start service is quick and efficient,
                    getting your vehicle running again in no time.</Text>
            }
        ]
    },
    "contact": {
        "url": "https://www.neworleanstowtruck.com/contact/",
        "metaData": {
            "title": "Contact New Orleans Towing Services: 24/7 Assistance | Call 504-294-5898",
            "description": "Contact New Orleans Towing Services for dependable 24/7 assistance. We're here to offer professional towing services when you need them most in New Orleans."
        },
        "h1": "Get in Touch With Us",
        "subtitle": <Text>We understand you have many options for towing services, so we thank you for choosing New
            Orleans Towing Service. Our team is ready to assist you and your vehicle. <Link href={'tel:504-294-5898'}
                                                                                            color='blue.500'>Call us
                anytime!</Link></Text>,
        "sectionHeading": "Why Choose New Orleans Towing Service?",
        "signUpDescription": "Prefer to reach us online? Please fill out the contact form below, and we'll respond promptly.",
        "feedbackText": "Your feedback is invaluable to us! We welcome any questions or concerns you may have. Your input helps us maintain high customer satisfaction.",
        "closingText": "Reliable and professional service in New Orleans, Louisiana.",
        "valueProps": [
            {
                "title": "Around the Clock Service",
                "description": "Vehicle emergencies can happen at any time. Our team of licensed professionals and extensive network of tow trucks are available 24/7 to provide rapid assistance."
            },
            {
                "title": "Repair and Recovery Services",
                "description": "Beyond towing, we offer a range of repair and recovery services. Our expert technicians are equipped to handle most automobile issues on the spot."
            },
            {
                "title": "High-Quality Equipment",
                "description": "Our drivers, with years of expertise, use cutting-edge equipment to safely and swiftly complete tasks. We can handle vehicles of all sizes."
            },
            {
                "title": "Long-Distance Towing",
                "description": "Whether you're near or far, we offer professional long-distance towing services, transporting your vehicle safely and cost-effectively."
            },
            {
                "title": "Our Promise to You",
                "description": "Customer ease during stressful times is our top priority. We strive to exceed expectations with our excellent customer service."
            }
        ]
    },
    "blog": {
        "url": "https://www.neworleanstowtruck.com/blog",
        "metaData": {
            "title": "New Orleans Towing Services: Reliable & Professional Solutions | Blog",
            "description": "Dependable 24/7 towing services in New Orleans. Quick assistance for roadside emergencies and vehicle transport. Contact us for quality towing at competitive rates."
        },
        "h1": "Articles and Tips",
        "h2": "Our Blog",
        "heading": "New Orleans Towing Service is always ready to provide fast, professional service. Remember, for any roadside assistance or towing services, our team is just a call away.",
        posts: posts
    },
    "emergencyTowing": {
        "url": "https://www.neworleanstowtruck.com/emergency-towing/",
        "metaData": {
            "title": "24/7 Emergency Towing in New Orleans, LA | New Orleans Towing Service",
            "description": "New Orleans Towing Service offers 24/7 emergency towing in New Orleans, LA. Contact us at 504-294-5898 for prompt, reliable towing services."
        },
        "h1": "24/7 Emergency Towing Services in New Orleans, LA",
        "subtitle": "New Orleans Towing Service provides fast, dependable emergency towing services around the clock, ensuring help is always available when you need it.",
        "body": <EmergencyTowing/>
    },
    roadsideAssistance: {
        "url": "https://www.neworleanstowtruck.com/roadside-assistance/",
        "metaData": {
            "title": "Expert Roadside Assistance in New Orleans, LA | New Orleans Towing Service",
            "description": "New Orleans Towing Service provides prompt and reliable roadside assistance in New Orleans. Contact us at 504-294-5898 for immediate support."
        },
        "h1": "Comprehensive Roadside Assistance in New Orleans, LA",
        "subtitle": "Our team is your go-to source for fast, dependable roadside assistance, available 24/7 to handle your roadside emergencies.",
        body: <RoadsideAssistance/>
    },
    flatbedTowing: {
        "url": "https://www.neworleanstowtruck.com/flatbed-towing/",
        "metaData": {
            "title": "Secure Flatbed Towing in New Orleans, LA | New Orleans Towing Service",
            "description": "For safe and efficient flatbed towing services in New Orleans, turn to New Orleans Towing Service. Contact us for your vehicle transportation needs."
        },
        "h1": "Top-Quality Flatbed Towing Services in New Orleans, LA",
        "subtitle": "Choose us for secure and efficient flatbed towing services, ensuring your vehicle is transported safely.",
        body: <FlatbedTowing/>
    },
    carLockoutService: {
        "url": "https://www.neworleanstowtruck.com/car-lockout-service/",
        "metaData": {
            "title": "Fast Car Lockout Services in New Orleans, LA | New Orleans Towing Service",
            "description": "Locked out of your car? New Orleans Towing Service provides quick and professional car lockout services. Call us for rapid assistance."
        },
        "h1": "Professional Car Lockout Services in New Orleans, LA",
        "subtitle": "Our team offers fast, efficient car lockout services to help you regain access to your vehicle quickly.",
        body: <CarLockoutService/>
    },
    vehicleRecovery: {
        "url": "https://www.neworleanstowtruck.com/vehicle-recovery/",
        "metaData": {
            "title": "Efficient Vehicle Recovery Services in New Orleans, LA | New Orleans Towing Service",
            "description": "For professional and reliable vehicle recovery in New Orleans, turn to New Orleans Towing Service. We're here to assist in all recovery situations."
        },
        "h1": "Professional Vehicle Recovery Services in New Orleans, LA",
        "subtitle": "Our team provides efficient and safe vehicle recovery services, available 24/7 for any situation.",
        body: <VehicleRecovery/>
    },
    flatTire: {
        "url": "https://www.neworleanstowtruck.com/flat-tire-services/",
        "metaData": {
            "title": "Reliable Flat Tire Services in New Orleans, LA | New Orleans Towing Service",
            "description": "Need quick flat tire services in New Orleans? Contact New Orleans Towing Service for fast and dependable tire repairs and replacements."
        },
        "h1": "Expert Flat Tire Services in New Orleans, LA",
        "subtitle": "Offering rapid and reliable flat tire solutions to get you safely back on the road.",
        body: <FlatTire/>
    },
    longDistanceTowing: {
        "url": "https://www.neworleanstowtruck.com/long-distance-towing/",
        "metaData": {
            "title": "Long-Distance Towing Services in New Orleans, LA | New Orleans Towing Service",
            "description": "For reliable long-distance towing across New Orleans and beyond, trust New Orleans Towing Service. We ensure safe transport of your vehicle."
        },
        "h1": "Trusted Long-Distance Towing Services in New Orleans, LA",
        "subtitle": "Our long-distance towing services are designed to transport your vehicle securely, no matter the distance.",
        body: <LongDistanceTowing/>
    },
    fuelDelivery: {
        "url": "https://www.neworleanstowtruck.com/fuel-delivery/",
        "metaData": {
            "title": "Quick Fuel Delivery Services in New Orleans, LA | New Orleans Towing Service",
            "description": "Run out of gas in New Orleans? Our fast fuel delivery services will get you back on the road in no time."
        },
        "h1": "Emergency Fuel Delivery Services in New Orleans, LA",
        "subtitle": "We provide swift and reliable fuel delivery to ensure you're never stranded with an empty tank.",
        body: <FuelDelivery/>
    },
    jumpStartService: {
        "url": "https://www.neworleanstowtruck.com/jump-start-service/",
        "metaData": {
            "title": "Professional Jump Start Service in New Orleans, LA | New Orleans Towing Service",
            "description": "Dead battery? Call New Orleans Towing Service for fast and effective jump start services."
        },
        "h1": "Dependable Jump Start Services in New Orleans, LA",
        "subtitle": "Our team is ready to assist with quick and effective jump start services, ensuring your vehicle is up and running again swiftly.",
        body: <JumpStart/>
    },
    "testimonials": {
        "h1": "Why Our Customers Trust Us",
        "h2": "Testimonials",
        "heading": "Hear from our satisfied customers about their experiences with New Orleans Towing Service.",
        "testimonials": [
            {
                "avatarUrl": "https://i.pravatar.cc/150?img=68",
                "name": "Mark M.",
                "title": "New Orleans, LA",
                "quote": "I had a fantastic experience with New Orleans Towing Service when my car broke down. They were prompt, courteous, and very professional."
            },
            {
                "avatarUrl": "https://i.pravatar.cc/150?img=45",
                "name": "Sophia K.",
                "title": "New Orleans, LA",
                "quote": "New Orleans Towing Service saved me when I got a flat tire on my way to work. They arrived quickly and fixed my tire in no time. Exceptional service!"
            },
            {
                "avatarUrl": "https://i.pravatar.cc/150?img=30",
                "name": "Jamie G.",
                "title": "New Orleans, LA",
                "quote": "The best towing service I've used in New Orleans! They were very responsive and handled my car with great care. I'm extremely satisfied with their service."
            }
        ]
    },
    "404": {
        "metaData": {
            "title": "404 | Page Not Found - New Orleans Towing Service | 24-Hour Towing | 504-294-5898",
            "description": "Oops! The page you're looking for could not be found. New Orleans Towing Service offers professional towing and roadside assistance. Contact us for reliable help."
        },
        "h1": "Oops, Page Not Found",
        "subtitle": "The page you are looking for doesn't exist or has been moved.",
        "p": "In need of emergency towing, vehicle recovery, or any other roadside service? We've got you covered 24/7 at New Orleans Towing Service."
    }
}