import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const VehicleRecovery = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Professional Vehicle Recovery Services in New Orleans, LA</Heading>
                <Text>
                    In the event of an accident or off-road incident, prompt and efficient vehicle recovery is crucial.
                    New Orleans Towing Service specializes in vehicle recovery in New Orleans, LA, offering safe,
                    efficient, and timely retrieval of vehicles under various circumstances. Whether your vehicle is
                    stuck, overturned, or involved in an accident, our skilled team is well-equipped to handle complex
                    recovery tasks.
                </Text>
                <Text>
                    We employ the latest tools and techniques to ensure the safe recovery of your vehicle, aiming to
                    minimize any additional damage.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Advanced Recovery Equipment:</b> Our recovery equipment is top-of-the-line, suitable for a
                        diverse array of vehicle recovery scenarios.
                    </ListItem>
                    <ListItem>
                        <b>Experienced Recovery Specialists:</b> Our team is proficient in various recovery techniques,
                        ensuring your vehicle is retrieved safely and effectively.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Service:</b> Vehicle recovery situations can arise at any hour. We offer round-the-clock
                        services to respond to your needs immediately.
                    </ListItem>
                    <ListItem>
                        <b>Safe and Secure Handling:</b> The safety of your vehicle is a priority during the recovery
                        process, and we use methods that prevent further damage.
                    </ListItem>
                    <ListItem>
                        <b>Comprehensive Solutions:</b> In addition to vehicle recovery, we provide other services such
                        as <Link href={"/emergency-towing"}>emergency towing</Link>, <Link
                        href={"/roadside-assistance"}>roadside assistance</Link>, and <Link href={"/flatbed-towing"}>flatbed
                        towing</Link>, offering a complete range of vehicle assistance services.
                    </ListItem>
                </UnorderedList>
                <Text>
                    New Orleans Towing Service is your reliable partner for vehicle recovery services in New Orleans,
                    LA. We are dedicated to delivering fast, safe, and professional recovery solutions.
                </Text>
                <Heading as={'h4'}>Expert Vehicle Recovery Assistance</Heading>
                <Text>
                    If you require vehicle recovery services in New Orleans, LA, don't hesitate to reach out to us. Our
                    experienced team is ready to provide you with efficient and reliable recovery services.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for expert vehicle recovery assistance.
                    </Link>
                </Text>
                <Text>
                    Rely on New Orleans Towing Service for all your vehicle recovery needs and experience the
                    professionalism and efficiency that distinguish us.
                </Text>
            </Stack>
        </Box>
    )
}