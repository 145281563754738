import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const JumpStart = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Prompt Jump Start Services in New Orleans, LA</Heading>
                <Text>
                    A dead battery can leave you stranded, but New Orleans Towing Service is here to help. We offer
                    quick and effective jump start services in New Orleans, LA, to revive your vehicle’s battery and get
                    you back on the road quickly. Our technicians are equipped with the necessary tools and expertise to
                    safely jump start your vehicle, ensuring a smooth and hassle-free service.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Rapid Response:</b> We prioritize swift response to your call, ensuring that you're not
                        waiting long for assistance.
                    </ListItem>
                    <ListItem>
                        <b>Safe and Efficient Jump Starts:</b> Our team uses safe and proven methods to jump start your
                        vehicle, avoiding any potential damage.
                    </ListItem>
                    <ListItem>
                        <b>Skilled Technicians:</b> Our professionals are trained to handle a variety of battery-related
                        issues, ensuring a competent and secure service.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Availability:</b> Our jump start service is available at any time, providing you with
                        reliable assistance whenever you need it.
                    </ListItem>
                    <ListItem>
                        <b>Comprehensive Roadside Assistance:</b> In addition to jump starts, we offer services
                        like <Link href={"/flat-tire-services"}>flat tire services</Link> and <Link
                        href={"/fuel-delivery"}>fuel delivery</Link>, covering a broad spectrum of roadside emergencies.
                    </ListItem>
                </UnorderedList>
                <Text>
                    For fast and dependable jump start services in New Orleans, LA, choose New Orleans Towing Service.
                    We're committed to getting your vehicle running again with our efficient and expert service.
                </Text>
                <Heading as={'h4'}>Immediate Assistance for Dead Batteries</Heading>
                <Text>
                    If you're facing a dead battery in New Orleans, LA, don't hesitate to call New Orleans Towing
                    Service. Our team is ready to provide prompt and reliable jump start assistance.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for immediate jump start service.
                    </Link>
                </Text>
                <Text>
                    Trust New Orleans Towing Service for all your jump start needs and experience the reassurance that
                    comes with our professional service.
                </Text>
            </Stack>
        </Box>
    )
}