export const posts = [
    {
        "id": "1",
        "title": "Why Choose New Orleans Towing Service?",
        "excerpt": "Exploring the best towing options in New Orleans? Discover what sets New Orleans Towing Service apart. From our rapid response times to expert handling, we'll delve into what you should look for in an ideal towing service in the vibrant city of New Orleans.",
        "slug": "why-choose-new-orleans-towing-service",
        "tags": ["New Orleans", "Towing Service", "Reliable", "Expertise"],
        "metaDescription": "Uncover the key reasons to choose New Orleans Towing Service for reliable, expert towing solutions in New Orleans, Louisiana.",
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        "id": "2",
        "title": "Affordable Towing in New Orleans: A Guide to Smart Choices",
        "excerpt": "Seeking affordable yet reliable towing in New Orleans? Our comprehensive guide covers essential tips for finding the perfect balance between cost-effectiveness and quality service. Navigate your options with confidence!",
        "slug": "affordable-towing-in-new-orleans",
        "tags": ["Affordable", "Towing", "New Orleans", "Guide"],
        "metaDescription": "Learn how to find affordable and reliable towing services in New Orleans with our detailed guide, ensuring you make a well-informed decision.",
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        "id": "3",
        "title": "New Orleans Towing Service: A Beacon of Safety and Reliability",
        "excerpt": "Vehicle emergencies can occur anytime, anywhere. In a bustling city like New Orleans, having a dependable towing service is essential for safety and peace of mind. Join us as we explore why New Orleans Towing Service is committed to being your trustworthy partner during every roadside emergency.",
        "slug": "new-orleans-towing-safety-commitment",
        "tags": ["Safety", "Reliability", "Towing", "New Orleans"],
        "metaDescription": "Discover the importance of reliable towing services in New Orleans and how New Orleans Towing Service upholds its commitment to safety and peace of mind.",
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        "id": "4",
        "title": "Exploring the Spectrum of Roadside Assistance in New Orleans",
        "excerpt": "When car troubles strike, towing is not the only solution. Dive into the world of comprehensive roadside assistance with New Orleans Towing Service. From tackling flat tires to addressing battery woes, we unravel how our diverse services can be your savior on unfamiliar roads.",
        "slug": "roadside-assistance-new-orleans",
        "tags": ["Roadside Assistance", "New Orleans", "Emergency Services", "Comprehensive Care"],
        "metaDescription": "Learn about the full range of roadside assistance services offered by New Orleans Towing Service, extending beyond just towing. Get insights into how these services can rescue you in various roadside emergencies.",
        lastModified: new Date('2023-12-03').toISOString()
    }
]
