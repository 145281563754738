import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const FlatbedTowing = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Premier Flatbed Towing Services in New Orleans, LA</Heading>
                <Text>
                    New Orleans Towing Service recognizes the importance of transporting your vehicle with utmost care
                    and security. Our flatbed towing services in New Orleans, LA, are specifically designed to offer
                    superior protection for your vehicle during transport. Ideal for luxury cars, vintage automobiles,
                    or vehicles requiring special handling, our flatbed tow trucks are the perfect choice for these
                    needs.
                </Text>
                <Text>
                    Our team is dedicated to providing high-quality, efficient flatbed towing services. We understand
                    the unique requirements of different vehicles and customize our services for the best care of your
                    vehicle, combining our professional expertise with a thorough understanding of flatbed towing's
                    specific demands.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Superior Vehicle Protection:</b> Our flatbed tow trucks come equipped with modern technology
                        and features, ensuring your vehicle is transported without any risk of damage or wear.
                    </ListItem>
                    <ListItem>
                        <b>Expert Handling:</b> Our operators are experienced in securely loading, transporting, and
                        unloading all types of vehicles, guaranteeing your vehicle's condition is preserved throughout
                        its journey.
                    </ListItem>
                    <ListItem>
                        <b>Versatile Towing Solutions:</b> Flatbed towing is suitable for various vehicles, including
                        all-wheel drives, motorcycles, luxury cars, and more, offering versatile solutions for all your
                        towing requirements.
                    </ListItem>
                    <ListItem>
                        <b>Reliable Service:</b> We are known for our punctuality and dependability, working efficiently
                        to ensure your vehicle is towed without any unnecessary delays or complications.
                    </ListItem>
                    <ListItem>
                        <b>Customer-Centric Approach:</b> At New Orleans Towing Service, we prioritize our customers. We
                        listen to your needs and provide customized services to meet your specific towing requirements.
                    </ListItem>
                </UnorderedList>
                <Text>
                    For secure and dependable flatbed towing in New Orleans, LA, choose New Orleans Towing Service. Our
                    commitment to excellence ensures your vehicle is in the safest hands.
                </Text>
                <Heading as={'h4'}>Advantages of Flatbed Towing</Heading>
                <Text>
                    Flatbed towing provides numerous benefits compared to traditional towing methods. It's the safest
                    way to transport a vehicle, avoiding any wear and tear by keeping all four wheels off the ground,
                    which is particularly vital for vehicles with low clearance, luxury models, or those needing extra
                    care during transit.
                </Text>
                <Heading as={'h4'}>Comprehensive Towing and Roadside Assistance</Heading>
                <Text>
                    Alongside our flatbed towing services, we offer a range of other towing and roadside assistance
                    options. Whether you require <Link href={"/emergency-towing"}>emergency towing</Link>, <Link
                    href={"/roadside-assistance"}>roadside assistance</Link>, <Link href={"/vehicle-recovery"}>vehicle
                    recovery</Link>, <Link href={"/long-distance-towing"}>long-distance towing</Link>, or <Link
                    href={"/car-lockout-service"}>car lockout services</Link>, our team is prepared to assist you.
                </Text>
                <Text>
                    Our dedication to delivering comprehensive and reliable services makes us a trusted partner for all
                    your vehicle transportation and roadside assistance needs in New Orleans, LA.
                </Text>
                <Heading as={'h4'}>Contact Us for Trusted Flatbed Towing</Heading>
                <Text>
                    For professional and dependable flatbed towing services in New Orleans, LA, contact New Orleans
                    Towing Service. We're here to ensure your vehicle is transported safely and efficiently.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for immediate assistance.
                    </Link>
                </Text>
                <Text>
                    Rely on us for all your towing needs, and experience the assurance of knowing your vehicle is in
                    expert hands.
                </Text>
            </Stack>
        </Box>
    )
}