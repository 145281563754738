import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const CarLockoutService = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Reliable Car Lockout Services in New Orleans, LA</Heading>
                <Text>
                    Being locked out of your car can be a highly stressful experience. New Orleans Towing Service
                    provides quick and professional car lockout services in New Orleans, LA, to help you regain access
                    to your vehicle efficiently and safely. Our team is equipped with the latest tools and expertise to
                    address any car lockout situation, ensuring a smooth and hassle-free resolution.
                </Text>
                <Text>
                    We recognize the urgency of car lockout incidents and are committed to delivering swift and
                    effective service. Whether your keys are lost, locked inside your car, or if you're facing any
                    lock-related challenges, our experienced technicians are on standby to assist you, day or night.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Fast Response Time:</b> We pride ourselves on our ability to respond promptly to your needs,
                        reducing your wait time and alleviating your stress.
                    </ListItem>
                    <ListItem>
                        <b>Damage-Free Techniques:</b> Our skilled technicians utilize advanced tools and methods to
                        unlock your car without causing any harm to the vehicle.
                    </ListItem>
                    <ListItem>
                        <b>All Vehicle Types:</b> Our team is well-prepared to manage lockout situations for a diverse
                        range of vehicle makes and models, from sedans to SUVs and trucks.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Availability:</b> Car lockouts can occur unexpectedly at any hour. Our services are
                        accessible around the clock, ensuring you’re never left stranded.
                    </ListItem>
                    <ListItem>
                        <b>Professional and Courteous Service:</b> Our team is not only technically proficient but also
                        recognized for their polite and understanding approach, easing the stress of lockout situations.
                    </ListItem>
                </UnorderedList>
                <Text>
                    With New Orleans Towing Service, you can have peace of mind knowing that efficient help is just a
                    phone call away for any car lockout situation in New Orleans, LA.
                </Text>
                <Heading as={'h4'}>Comprehensive Solutions Beyond Lockouts</Heading>
                <Text>
                    Beyond car lockout services, we offer a complete range of towing and roadside assistance services.
                    This includes <Link href={"/emergency-towing"}>emergency towing</Link>, <Link
                    href={"/roadside-assistance"}>roadside assistance</Link>, <Link href={"/vehicle-recovery"}>vehicle
                    recovery</Link>, <Link href={"/long-distance-towing"}>long-distance towing</Link>, and <Link
                    href={"/flatbed-towing"}>flatbed towing</Link>. Our aim is to be your comprehensive solution for any
                    vehicle-related emergency.
                </Text>
                <Text>
                    Whether it's a lockout, a tow, or roadside assistance you need, New Orleans Towing Service is
                    equipped to provide efficient, dependable, and professional service.
                </Text>
                <Heading as={'h4'}>Immediate Lockout Assistance Available</Heading>
                <Text>
                    If you're locked out of your car in New Orleans, LA, don't hesitate to reach out to us. Our friendly
                    and professional team is ready to offer quick and effective lockout services to help you resume your
                    journey.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.telLink} for immediate assistance.
                    </Link>
                </Text>
                <Text>
                    Rely on New Orleans Towing Service for all your car lockout needs and experience the reassurance
                    that comes with our expert services.
                </Text>
            </Stack>
        </Box>
    )
}