import React from "react";
import {Box, Heading, Link, ListItem, Stack, Text, UnorderedList} from "@chakra-ui/react";
import {SiteData} from "../../Constants/siteData";

export const FlatTire = () => {
    return (
        <Box>
            <Stack spacing={3} py={5}>
                <Heading as={'h3'}>Expert Flat Tire Services in New Orleans, LA</Heading>
                <Text>
                    A flat tire can bring your journey to an unexpected halt. New Orleans Towing Service offers prompt
                    and reliable flat tire services in New Orleans, LA, ensuring you're back on the road swiftly and
                    safely. Our team is equipped to handle tire changes and repairs for a variety of vehicles, providing
                    a quick and efficient solution to your flat tire woes.
                </Text>
                <Text>
                    We understand the inconvenience caused by a flat tire and are committed to providing fast, effective
                    service to address the issue without delay.
                </Text>
                <UnorderedList spacing={3}>
                    <ListItem>
                        <b>Rapid Response:</b> We prioritize a quick response to your call, ensuring that we minimize
                        your downtime and inconvenience.
                    </ListItem>
                    <ListItem>
                        <b>Skilled Tire Change and Repair:</b> Our technicians are proficient in both changing flat
                        tires and performing necessary repairs, ensuring your safety on the road.
                    </ListItem>
                    <ListItem>
                        <b>Equipped for Various Vehicles:</b> Whether you drive a car, SUV, truck, or any other type of
                        vehicle, we are prepared to address your flat tire needs with the right equipment and expertise.
                    </ListItem>
                    <ListItem>
                        <b>24/7 Service:</b> Flat tire troubles can occur at any time. Our services are available round
                        the clock, so you're never stranded with a flat tire.
                    </ListItem>
                    <ListItem>
                        <b>Customer-Focused Approach:</b> We understand the stress of roadside emergencies and strive to
                        provide a service that is not only efficient but also empathetic and customer-oriented.
                    </ListItem>
                </UnorderedList>
                <Text>
                    Choose New Orleans Towing Service for top-tier flat tire services in New Orleans, LA. We are
                    committed to getting you back on your journey with minimal hassle and maximum safety.
                </Text>
                <Heading as={'h4'}>Comprehensive Roadside Assistance</Heading>
                <Text>
                    In addition to flat tire services, we offer a wide range of roadside assistance services,
                    including <Link href={"/emergency-towing"}>emergency towing</Link>, <Link
                    href={"/roadside-assistance"}>roadside assistance</Link>, <Link href={"/vehicle-recovery"}>vehicle
                    recovery</Link>, <Link href={"/car-lockout-service"}>car lockout services</Link>, and more. Our goal
                    is to be your comprehensive resource for any roadside emergency.
                </Text>
                <Text>
                    Whether it's a flat tire or any other roadside issue, New Orleans Towing Service is equipped to
                    provide prompt, reliable, and professional service.
                </Text>
                <Heading as={'h4'}>Immediate Assistance for Flat Tires</Heading>
                <Text>
                    If you encounter a flat tire in New Orleans, LA, don't hesitate to contact us. Our team is ready to
                    provide quick and dependable service to resolve your issue and help you continue your journey.
                </Text>
                <Text>
                    <Link color="teal.500" href={SiteData.telLink}>
                        Call us at {SiteData.phoneNumber} for immediate flat tire assistance.
                    </Link>
                </Text>
                <Text>
                    Trust New Orleans Towing Service for all your flat tire needs and experience the peace of mind that
                    comes with our expert service.
                </Text>
            </Stack>
        </Box>
    )
}